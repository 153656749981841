import styled from "styled-components";

export const Container = styled.section`
  padding: var(--space-16) var(--space-8);
  min-height: 100vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: var(--space-16);
  background: var(--gradient-surface);
  position: relative;
  isolation: isolate;
  
  &::before {
    content: '';
    position: absolute;
    inset: 0;
    background: radial-gradient(
      circle at 50% 50%,
      var(--primary) 0%,
      transparent 70%
    );
    opacity: 0.05;
    z-index: -1;
    animation: pulse 8s ease-in-out infinite;
  }
  
  @keyframes pulse {
    0%, 100% { transform: scale(1); opacity: 0.05; }
    50% { transform: scale(1.5); opacity: 0.03; }
  }
  .hero-text {
    flex: 1;
    
    & > p {
      font-size: 2rem;
      color: var(--primary-light);
      display: flex;
      align-items: center;
      gap: var(--space-2);
      margin-bottom: var(--space-4);
    }
    
    h1 {
      font-size: clamp(4rem, 7vw, 7rem);
      line-height: 1.1;
      background: linear-gradient(to right, var(--white), var(--gray-200));
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      margin-bottom: var(--space-6);
    }

    h3 {
      font-size: 2.4rem;
      color: var(--success);
      margin-bottom: var(--space-4);
      font-weight: 500;
    }
    
    p.small-resume {
      font-size: 1.8rem;
      color: var(--gray-200);
      margin-bottom: var(--space-8);
      max-width: 60ch;
      line-height: 1.6;
    }
  }
// New added
  .social-media {
    display: flex;
    align-items: center;
    gap: var(--space-4);
    margin-top: var(--space-8);

    a {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 4.5rem;
      height: 4.5rem;
      border-radius: 50%;
      transition: all var(--transition-normal);

      &:hover {
        transform: translateY(-2px);
      }

      img {
        width: 2.4rem;
        height: 2.4rem;
        
        &[alt="GitHub"] {
          filter: brightness(0) invert(1);
        }
      }
    }
  }


  .button{
    margin-top: 5rem;
    padding: 1.4rem 6rem;
  }

  .custom-title {
    font-size: 2.5em; /* Adjust size as needed */
    font-weight: bold; /* Optional: make it bold */
    margin: 0.5em 0; /* Optional: adjust spacing */
  }

  // .hero-image{
  //   img{
  //     max-width: 500px;
  //   }
  // }
  
  
  .hero-image {
    flex: 1;
    max-width: 600px;
    
    .image-grid {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(4, 1fr);
      gap: var(--space-4);
      transform-style: preserve-3d;
      perspective: 1000px;
      
      .image-container {
        position: relative;
        border-radius: 1.2rem;
        overflow: hidden;
        box-shadow: var(--shadow-md);
        transition: all var(--transition-bounce);
        transform: translateZ(0);
        
        &:hover {
          transform: translateY(-4px) translateZ(20px) rotateX(2deg) rotateY(2deg);
          box-shadow: var(--shadow-lg), var(--shadow-glow);
          
          .banner {
            opacity: 1;
            transform: translateY(0);
          }
          
          img {
            transform: scale(1.1);
          }
        }
        
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          transition: transform var(--transition-normal);
        }
        
        .banner {
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          padding: var(--space-2) var(--space-3);
          text-align: center;
          font-size: 1.4rem;
          font-weight: 600;
          color: var(--white);
          backdrop-filter: var(--backdrop-blur);
          opacity: 0;
          transform: translateY(100%);
          transition: all var(--transition-bounce);
          
          &.failed {
            background: linear-gradient(135deg, rgba(239, 68, 68, 0.9), rgba(220, 38, 38, 0.9));
          }
          
          &.exited {
            background: linear-gradient(135deg, rgba(37, 99, 235, 0.9), rgba(29, 78, 216, 0.9));
          }
          
          &.active {
            background: linear-gradient(135deg, rgba(16, 185, 129, 0.9), rgba(6, 148, 162, 0.9));
          }
          
          &.pivot {
            background: linear-gradient(135deg, rgba(245, 158, 11, 0.9), rgba(217, 119, 6, 0.9));
          }
        }
      }
    }
  }


  @media (max-width: 1024px) {
    flex-direction: column;
    padding-top: var(--space-12);
    text-align: center;
    
    .hero-text {
      h1 {
        font-size: clamp(3.5rem, 6vw, 5rem);
      }
      
      p.small-resume {
        margin-left: auto;
        margin-right: auto;
      }
    }
    
    .social-media {
      justify-content: center;
    }
    
    .hero-image {
      margin-top: var(--space-8);
      width: 100%;
      max-width: 500px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  @media (max-width: 640px) {
    padding-top: var(--space-8);
    
    .hero-image {
      display: none;
    }
  }
`
