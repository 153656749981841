import styled from "styled-components";

export const Container = styled.section`
  padding: var(--space-16) var(--space-8);
  display: grid;
  grid-template-columns: 1.2fr 0.8fr;
  gap: var(--space-16);
  align-items: start;

  .hard-skills{
    margin-top: 1.6rem;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 1.8rem;
  }
  .hability{
    display: flex;
    flex-direction: column;
    align-items: center;

    img{
      width: 3.4rem;
    }
  }



  // metrics section

  .metrics-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
    gap: var(--space-4);
    margin-top: var(--space-8);
  }

  .metric-card {
    background: linear-gradient(145deg, var(--gray-900), var(--gray-700));
    padding: var(--space-6);
    border-radius: 1.6rem;
    text-align: center;
    border: 1px solid var(--gray-700);
    box-shadow: var(--shadow-md);
    transition: all var(--transition-normal);
    position: relative;
    overflow: hidden;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 2px;
      background: linear-gradient(90deg, var(--primary), var(--success));
      transform: scaleX(0);
      transform-origin: left;
      transition: transform var(--transition-normal);
    }

    &:hover {
      transform: translateY(-4px);
      box-shadow: var(--shadow-lg);
      border-color: var(--gray-200);

      &::before {
        transform: scaleX(1);
      }
    }

    h4 {
      font-size: 3.2rem;
      font-weight: 700;
      background: linear-gradient(90deg, var(--primary-light), var(--success));
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      margin-bottom: var(--space-2);
    }

    p {
      font-size: 1.4rem;
      color: var(--gray-200);
      font-weight: 500;
      letter-spacing: 0.5px;
    }
  }





  h2 {
    font-size: 4rem;
    font-weight: 700;
    background: linear-gradient(90deg, var(--primary-light), var(--success));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom: var(--space-8);
  }

  h3 {
    font-size: 2.8rem;
    color: var(--primary-light);
    margin: var(--space-12) 0 var(--space-6);
    font-weight: 600;
  }

  p {
    font-size: 1.8rem;
    line-height: 1.7;
    color: var(--gray-200);
    margin-bottom: var(--space-6);
    
    &:last-child {
      margin-bottom: 0;
    }
  }
  
  

  .about-image {
    position: sticky;
    top: var(--space-16);
    
    img {
      width: 100%;
      border-radius: 2.4rem;
      box-shadow: var(--shadow-lg);
      transition: all var(--transition-normal);
      filter: brightness(0.9) contrast(1.1);
      
      &:hover {
        transform: scale(1.02);
        filter: brightness(1) contrast(1);
      }
    }
  }

  @media (max-width: 1024px) {
    grid-template-columns: 1fr;
    gap: var(--space-12);
    
    .about-image {
      position: relative;
      top: 0;
      max-width: 600px;
      margin: 0 auto;
    }
  }

  @media (max-width: 768px) {
    padding: var(--space-8) var(--space-4);
    
    h2 {
      font-size: 3.2rem;
    }
    
    h3 {
      font-size: 2.4rem;
    }
    
    .metrics-grid {
      grid-template-columns: 1fr;
    }
    
    .metric-card {
      padding: var(--space-4);
      
      h4 {
        font-size: 2.8rem;
      }
    }
  }
    
    
  }

`
