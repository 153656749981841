import { BrowserRouter } from "react-router-dom"
import { Container } from "./styles"
import ScrollAnimation from "react-animate-on-scroll"
import { NavHashLink } from "react-router-hash-link"
import { useEffect, useState } from "react"
import linkedin from '../../assets/linkedin.svg'
import githubIcon from '../../assets/github.svg'
import Hello from '../../assets/Hello.gif'

import img1 from '../../assets/oldsport-round.png';
import img2 from '../../assets/ihm-round.png';
import img3 from '../../assets/produ-round.png';
import img4 from '../../assets/omega-round.png';
import img5 from '../../assets/aqx-round.png';
import img6 from '../../assets/seven-round.png';
import img7 from '../../assets/vb-round.png';
import img8 from '../../assets/caplogiq-round.png';
import img9 from '../../assets/sympto-round.png';
import img10 from '../../assets/cognifyfx-round.png';

export function Hero() {
  const [isLoaded, setIsLoaded] = useState(false)
  
  useEffect(() => {
    // Add a small delay for smoother initial animation
    const timer = setTimeout(() => {
      setIsLoaded(true)
    }, 100)
    return () => clearTimeout(timer)
  }, [])

  const images = [
    { src: img1, status: "Failed" },
    { src: img2, status: "Failed" },
    { src: img3, status: "Failed" },
    { src: img4, status: "Exited" },
    { src: img5, status: "Exited" },
    { src: img6, status: "Active" },
    { src: img7, status: "Active" },
    { src: img8, status: "Active" },
    { src: img9, status: "Pivot" },
    { src: img10, status: "Active" }
  ];

  return (
    <Container id="home" className={isLoaded ? 'loaded' : ''}>
      <div className="hero-text">
        <ScrollAnimation animateIn="fadeInUp" duration={0.5}>
          <p>Hi <img src={Hello} alt="Hello" width="20px"/>, I'm</p>
        </ScrollAnimation>
        <ScrollAnimation animateIn="fadeInUp" duration={0.7} delay={0.2 * 1000}>
          <h1>Toby Messier</h1>
        </ScrollAnimation>
        <ScrollAnimation animateIn="fadeInUp" duration={0.5} delay={0.4 * 1000}>
          <h3>Serial Entrepreneur & Business Builder</h3>
        </ScrollAnimation>
        <ScrollAnimation animateIn="fadeInUp" duration={0.5} delay={0.6 * 1000}>
          <p className="small-resume">Transforming challenges into opportunities, I partner with passionate founders and solo build innovative, market-leading companies.</p>
        </ScrollAnimation>
        <ScrollAnimation animateIn="fadeInUp" duration={0.5} delay={0.8 * 1000}>
          <BrowserRouter>
            <NavHashLink smooth to="#about" className="button">Metrics</NavHashLink>
          </BrowserRouter>
        </ScrollAnimation>
        <ScrollAnimation animateIn="fadeInUp" duration={0.5} delay={1 * 1000}>
          <div className="social-media">
            <a
              href="https://www.linkedin.com/in/toby-messier"
              target="_blank"
              rel="noreferrer"
              aria-label="LinkedIn Profile"
            >
              <img src={linkedin} alt="LinkedIn" />
            </a>
            <a
              href="https://github.com/TobyMessier/"
              target="_blank"
              rel="noreferrer"
              aria-label="GitHub Profile"
            >
              <img src={githubIcon} alt="GitHub" />
            </a>
          </div>
        </ScrollAnimation>
      </div>
      <div className="hero-image">
        <ScrollAnimation animateIn="fadeInRight" duration={0.7} delay={1 * 1000}>
          <div className="image-grid">
            {images.map((image, index) => (
              <div 
                key={index} 
                className="image-container"
                style={{
                  animationDelay: `${0.1 * index}s`,
                  transform: `translateZ(${index * 2}px)`
                }}
              >
                <div className={`banner ${image.status.toLowerCase()}`}>
                  {image.status}
                </div>
                <img 
                  src={image.src} 
                  alt={`Project ${index + 1}`}
                  loading="lazy"
                />
              </div>
            ))}
          </div>
        </ScrollAnimation>
      </div>
    </Container>
  )
}
