// File: src/components/Project/styles.ts

import styled from "styled-components";


export const Container = styled.section`
  padding: var(--space-16) var(--space-8);
  background: linear-gradient(180deg, rgba(17,24,39,0.02) 0%, rgba(17,24,39,0.1) 100%);
  
  h2 {
    text-align: center;
    font-size: 4.8rem;
    font-weight: 700;
    background: linear-gradient(90deg, var(--primary-light), var(--success));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom: var(--space-12);
  }
  .projects {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: var(--space-6);
    padding: var(--space-4);
    overflow: hidden;

    .project {
      padding: var(--space-6);
      background: linear-gradient(145deg, var(--gray-900), var(--gray-700));
      border: 1px solid var(--gray-700);
      border-radius: 2rem;
      transition: all var(--transition-normal);
      display: flex;
      flex-direction: column;
      height: 100%;
      color: var(--white);
      position: relative;
      box-shadow: var(--shadow-md);
      
      &:hover {
        transform: translateY(-4px);
        box-shadow: var(--shadow-lg);
        border-color: var(--primary);
        
        .details {
          opacity: 1;
          transform: translateY(0);
          visibility: visible;
        }
      }

      header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: var(--space-6);
        
        .project-image {
          width: 6rem;
          height: 6rem;
          border-radius: 1.2rem;
          object-fit: cover;
          box-shadow: var(--shadow-sm);
          transition: transform var(--transition-normal);
          
          &:hover {
            transform: scale(1.05);
          }
        }

        .status-badge {
          font-size: 1.2rem;
          font-weight: 600;
          color: var(--white);
          padding: var(--space-1) var(--space-3);
          border-radius: 0.6rem;
          text-transform: uppercase;
          letter-spacing: 0.05em;
          backdrop-filter: blur(8px);
        }
      }

      .status-badge {
        &.learned {
          background: rgba(139, 92, 246, 0.9); /* Professional purple */
          border: 1px solid rgba(167, 139, 250, 0.3);
        }
        
        &.exited {
          background: rgba(37, 99, 235, 0.9);
          border: 1px solid rgba(59, 130, 246, 0.3);
        }
        
        &.active {
          background: rgba(16, 185, 129, 0.9);
          border: 1px solid rgba(34, 197, 94, 0.3);
        }
        
        &.pivot {
          background: rgba(245, 158, 11, 0.9);
          border: 1px solid rgba(234, 179, 8, 0.3);
        }
      }

      &:hover {
        transform: translateY(-4px);
        box-shadow: var(--shadow-lg);
        border-color: var(--primary);
        background: linear-gradient(145deg, var(--gray-800), var(--gray-700));
        
        .project-image {
          transform: scale(1.05);
        }
        
        h3 {
          color: var(--success);
        }
      }

      .body {
        flex: 1;
        
        h3 {
          font-size: 2.4rem;
          font-weight: 600;
          margin-bottom: var(--space-3);
          color: var(--primary-light);
        }
        
        .short-description {
          font-size: 1.6rem;
          color: var(--gray-200);
          margin-bottom: var(--space-4);
          line-height: 1.6;
          letter-spacing: 0.02em;
        }
        
        .details {
          max-height: 0;
          opacity: 0;
          overflow: hidden;
          transition: all var(--transition-normal);
          margin-top: var(--space-4);
          pointer-events: none;
          
          .improvement-title, .insight-title {
            font-size: 1.4rem;
            font-weight: 600;
            color: var(--success);
            margin-bottom: var(--space-2);
          }
          
          .improvement, .insight {
            font-size: 1.4rem;
            color: var(--gray-300);
            line-height: 1.7;
            margin-bottom: var(--space-4);
            letter-spacing: 0.02em;
          }
        }

        &:hover .details {
          max-height: 500px; /* Adjust based on maximum expected content height */
          opacity: 1;
          pointer-events: auto;
        }
      }

      .body {
        margin-top: 1rem;
      }
      
      h3{
        margin-bottom: 2rem;
      }

      p{
        letter-spacing: 0.12rem;
        margin-bottom: 2rem;
        a{
          color: #FFFF;
          border-bottom: 1px solid var(--green);
          transition: color 0.25s;
          &:hover{
            color: var(--green);
          }
        }
      }

      footer {
        margin-top: var(--space-6);
        
        .tech-list {
          display: flex;
          flex-wrap: wrap;
          gap: var(--space-2);
          
          li {
            font-size: 1.2rem;
            color: var(--gray-200);
            background: var(--gray-900);
            padding: var(--space-1) var(--space-3);
            border-radius: 2rem;
            border: 1px solid var(--gray-700);
            transition: all var(--transition-normal);
            
            &:hover {
              border-color: var(--primary);
              color: var(--primary-light);
            }
          }
        }
      }

    }
  }

  @media (max-width: 1200px) {
    .projects {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  @media (max-width: 768px) {
    padding: var(--space-8) var(--space-4);
    
    h2 {
      font-size: 3.6rem;
    }
    
    .projects {
      grid-template-columns: 1fr;
      gap: var(--space-4);
      
      .project {
        padding: var(--space-4);
        
        .body {
          h3 {
            font-size: 2rem;
          }
          
          .short-description {
            font-size: 1.4rem;
          }
          
          .details {
            max-height: 0;
            opacity: 0;
            
            &:hover {
              max-height: 500px;
              opacity: 1;
            }
          }
        }
      }
    }
  }
`
