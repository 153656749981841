// File: src/components/Project/Project.tsx

import { Container } from "./styles";
import ScrollAnimation from "react-animate-on-scroll";

// Import project images and statuses
import img1 from '../../assets/oldsport-round.png';
import img2 from '../../assets/ihm-round.png';
import img3 from '../../assets/produ-round.png';
import img4 from '../../assets/omega-round.png';
import img5 from '../../assets/aqx-round.png';
import img6 from '../../assets/seven-round.png';
import img7 from '../../assets/vb-round.png';
import img8 from '../../assets/caplogiq-round.png';
import img9 from '../../assets/sympto-round.png';
import img10 from '../../assets/cognifyfx-round.png';

const projects = [
  {
    src: img1,
    name: "OldSport",
    status: "Learned",
    shortDescription: "Sports marketing platform connecting advertisers with amateur leagues",
    improvement: "Balanced product development with market validation to ensure product-market fit",
    insight: "Focus on channels with higher spending capacity over granular targeting",
    labels: ["AdTech", "Sports", "MarketPlace"]
  },
  {
    src: img2,
    name: "IHM",
    status: "Learned",
    shortDescription: "Enterprise feedback platform with psychometric analysis",
    improvement: "Validate market demand before deep product development",
    insight: "Product excellence must align with urgent business needs",
    labels: ["Enterprise", "HR Tech", "Analytics"]
  },
  {
    src: img3,
    name: "Productor",
    status: "Learned",
    shortDescription: "Industry 4.0 manufacturing optimization platform",
    improvement: "Balance software scalability with service delivery",
    insight: "Clear product-service boundaries drive scalable growth",
    labels: ["Industry 4.0", "Manufacturing", "AI"]
  },
  {
    src: img4,
    name: "OMEGA",
    status: "Exited",
    shortDescription: "Finance and management education tailored for doctors.",
    improvement: "A more aggressive scaling approach could have significantly expanded the business. The market and interest levels were ripe for it. Don’t hesitate to aim for substantial growth.",
    insight: "Mission-driven initiatives attract strong interest, but clarifying early on that you’re a for-profit business is essential to avoid being viewed as a public service or NGO.",
    labels: ["Education", "Finance", "Healthcare Professionals"]
  },
  {
    src: img5,
    name: "AQX",
    status: "Exited",
    shortDescription: "Empowering the financial sector to quantify climate change.",
    improvement: "Raising capital earlier, especially for R&D-intensive projects, would have accelerated progress. Bootstrapping cost valuable time, while competitors raised more than our exit value to develop a comparable product.",
    insight: "Specialization in a niche, such as climate quantification for credit, enables rapid establishment as a global leader in that space.",
    labels: ["Climate Tech", "Quantification", "Finance"]
  },
  {
    src: img6,
    name: "SEVEN",
    status: "Pivot",
    shortDescription: "Behavioral health platform.",
    improvement: "Professional management needs maximum automation to prevent excessive time consumption.",
    insight: "Many professionals recognize the potential to offer improved services and are eager to share their expertise for greater impact.",
    labels: ["Healthtech", "Behavioral Health", "Professional Services"]
  },
  {
    src: img7,
    name: "Viral Blitz",
    status: "Active",
    shortDescription: "Crowdsourced advertising platform.",
    improvement: "Coordinating with numerous third parties can become a complex juggling act. Establishing a strategy with specific deadlines and contingency plans is essential.",
    insight: "Even seemingly straightforward missions often face significant challenges. If it hasn’t been done before, it may be more complex than it appears. Building an MVP may be easy, but scaling the full solution is not, which can make MVP validation insufficient.",
    labels: ["Advertising", "Crowdsourcing", "Social Media"]
  },
  {
    src: img8,
    name: "Caplogiq",
    status: "Learned",
    shortDescription: "Analytics platform for hockey contract optimization",
    improvement: "Structure hobby projects with business fundamentals",
    insight: "Sports analytics requires both data expertise and deep domain knowledge",
    labels: ["Sports Tech", "Analytics", "Contract Data"]
  },
  {
    src: img9,
    name: "SymptoTalk",
    status: "Pivot",
    shortDescription: "Patient pre-assessment platform for doctors.",
    improvement: "Understand early on who is positioned to pay for the service. Misaligned incentives can arise if it’s unclear whether clinics, hospitals, insurers, or governments are the primary stakeholders.",
    insight: "Medical software faces unique challenges, especially in areas with poorly aligned incentives. Address incentive alignment early on.",
    labels: ["Healthcare", "Pre-Assessment", "Patient Engagement"]
  },
  {
    src: img10,
    name: "CognifyFX",
    status: "Active",
    shortDescription: "Knowledge enhancement for high-demand, low-supply professionals.",
    improvement: "Focus intensely on a well-defined niche market. Avoid straying toward quick wins that detract from your core purpose.",
    insight: "Vertical AI holds immense potential, but legitimacy will be crucial for adoption. Building credibility in specific sectors will be paramount to success in this space.",
    labels: ["Knowledge Enhancement", "AI", "Professional Development"]
  }
];


export function Project() {
  return (
    <Container id="project">
      <h2>Venture Portfolio</h2>
      <div className="projects">
        {projects.map((project, index) => (
          <ScrollAnimation key={index} animateIn="flipInX">
            <div className="project">
              <header>
                <img src={project.src} alt={`${project.name} logo`} className="project-image" />
                <span className={`status-badge ${project.status.toLowerCase()}`}>
                  {project.status === "Learned" ? "Experience" : project.status}
                </span>
              </header>

              <div className="body">
                <h3>{project.name}</h3>
                <p className="short-description">{project.shortDescription}</p>

                <div className="details">
                  <p className="improvement-title">Potential for Improvement:</p>
                  <p className="improvement">{project.improvement}</p>
                  <p className="insight-title">Key Insight:</p>
                  <p className="insight">{project.insight}</p>
                </div>
              </div>

              <footer>
                <ul className="tech-list">
                  {project.labels.map((label, i) => (
                    <li key={i}>{label}</li>
                  ))}
                </ul>
              </footer>
            </div>
          </ScrollAnimation>
        ))}
      </div>
    </Container>
  );
}
