// File: src/styles/global.ts

import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
  :root{
    /* Modern Color System */
    --primary: #0ea5e9;
    --primary-light: #38bdf8;
    --primary-dark: #0284c7;
    --success: #10b981;
    --warning: #f59e0b;
    --danger: #ef4444;
    --black: #0f172a;
    --white: #ffffff;
    --gray-50: #f8fafc;
    --gray-100: #f1f5f9;
    --gray-200: #e2e8f0;
    --gray-700: #334155;
    --gray-900: #0f172a;
    
    /* Gradients */
    --gradient-primary: linear-gradient(135deg, var(--primary) 0%, var(--primary-dark) 100%);
    --gradient-text: linear-gradient(to right, var(--white), var(--gray-200));
    --gradient-surface: linear-gradient(180deg, rgba(15,23,42,0) 0%, rgba(15,23,42,0.05) 100%);
    
    /* Typography */
    --font-sans: 'Inter', -apple-system, BlinkMacSystemFont, sans-serif;
    
    /* Spacing System */
    --space-1: 0.25rem;
    --space-2: 0.5rem;
    --space-3: 0.75rem;
    --space-4: 1rem;
    --space-6: 1.5rem;
    --space-8: 2rem;
    --space-12: 3rem;
    --space-16: 4rem;
    
    /* Transitions & Animations */
    --transition-fast: 150ms cubic-bezier(0.4, 0, 0.2, 1);
    --transition-normal: 250ms cubic-bezier(0.16, 1, 0.3, 1);
    --transition-slow: 350ms cubic-bezier(0.16, 1, 0.3, 1);
    --transition-bounce: 500ms cubic-bezier(0.34, 1.56, 0.64, 1);
    
    /* Shadows & Effects */
    --shadow-sm: 0 1px 2px 0 rgb(0 0 0 / 0.05);
    --shadow-md: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
    --shadow-lg: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
    --shadow-glow: 0 0 20px rgba(14, 165, 233, 0.15);
    --backdrop-blur: blur(8px) saturate(180%);
    
    scroll-padding-top: 10rem;

    &.light{

      body{
        transition: 0.5s;
        background-color: #f5f5f5;
        color: var(--black);
      }

      .logo{
        color: var(--black);
      }

      header.header-fixed{
        transition: 0.5s;
        background-color: #f5f5f550;
        a{
          transition: 0.5s;
          color: black;
        }
        .menu,.menu:before, .menu:after{
          background-color: var(--black); 
        }
        .menu.active{
          background-color: rgba(555,555,555,0);
        }
      }

      footer.footer{
        transition: 0.5s;
        background-color: rgba(0,0,0,0.1);
        color: var(--black);
      }

      form{
        input,textarea{
          transition: 0.5s;
          border: solid 1px var(--black);
          color: var(--black);
          background: var(--white);
          &::placeholder{
            transition: 0.5s;
            color: var(--black);
          }
        }
      }

    }
  }

  ul, li {
    text-decoration: none;
    list-style: none;
    margin: 0;
    padding:0;
  }

  *{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  html{
    font-size: 62.5%;
  }

  body{
    font-size: 1.6rem;
    -webkit-font-smoothing: antialiased;
    background-color: var(--black);
    color: #FFFF;
  }

  body, input, textarea, button{
    font-family: 'Red Hat Display', sans-serif;
    font-weight: 400;
  }

  a{
    text-decoration: none;
  }

  button, .button{
    border: none;
    cursor: pointer;
    background: var(--gradient-primary);
    color: var(--white);
    padding: var(--space-4) var(--space-8);
    border-radius: 1rem;
    font-weight: 600;
    font-size: 1.6rem;
    transition: all var(--transition-bounce);
    box-shadow: var(--shadow-sm);
    position: relative;
    isolation: isolate;
    
    &::before {
      content: '';
      position: absolute;
      inset: 0;
      background: var(--gradient-primary);
      opacity: 0;
      transition: opacity var(--transition-normal);
      z-index: -1;
      border-radius: inherit;
      filter: blur(16px);
    }
    
    &:hover{
      transform: translateY(-2px);
      box-shadow: var(--shadow-md), var(--shadow-glow);
      
      &::before {
        opacity: 0.7;
      }
    }
    
    &:active {
      transform: translateY(0);
    }
  }

  button:disabled, .button:disabled{
    filter: brightness(0.8);
    cursor: not-allowed;
  }


  .logo{
    font-size: 3rem;
    color: #FFFF;
    // &::first-letter{
    //   color: var(--green);
    // }
  }
`
