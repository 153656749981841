import styled from "styled-components";


export const Container = styled.div`
  margin-top: var(--space-12);
  width: 100%;
  max-width: 76.8rem;
  margin-left: auto;
  margin-right: auto;
  
  h2 {
    text-align: center;
    font-size: 2.8rem;
    color: var(--gray-200);
    margin-bottom: var(--space-8);
    font-weight: 600;
  }
  
  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--space-4);
    width: 100%;
    
    input, textarea {
      width: 100%;
      padding: var(--space-4) var(--space-6);
      border-radius: 1.2rem;
      background: var(--gray-900);
      border: 1px solid var(--gray-700);
      font-size: 1.6rem;
      color: var(--gray-200);
      transition: all var(--transition-normal);
      box-shadow: var(--shadow-sm);
      
      &::placeholder {
        color: var(--gray-200);
        opacity: 0.5;
      }
      
      &:focus {
        border-color: var(--primary);
        box-shadow: 0 0 0 2px var(--primary-light);
      }
      
      &:hover:not(:focus) {
        border-color: var(--gray-200);
      }
    }

    textarea {
      height: 20rem;
      resize: vertical;
      min-height: 12rem;
      line-height: 1.6;
    }

    button {
      margin-top: var(--space-4);
      width: 100%;
      max-width: 36rem;
      height: 5.6rem;
      text-transform: uppercase;
      letter-spacing: 0.1em;
      font-weight: 600;
      
      &:disabled {
        opacity: 0.7;
        cursor: not-allowed;
        transform: none;
        
        &:hover {
          background-color: var(--primary);
          box-shadow: var(--shadow-sm);
        }
      }
    }
    
    .recaptcha-container {
      margin-top: var(--space-4);
    }
  }

  @media (max-width: 768px) {
    padding: 0 var(--space-4);
    
    h2 {
      font-size: 2.4rem;
    }
  }
`


export const ContainerSucces = styled.div`
  margin: var(--space-16) auto;
  text-align: center;
  max-width: 48rem;
  padding: var(--space-8);
  background: linear-gradient(145deg, var(--gray-900), var(--gray-700));
  border-radius: 2rem;
  border: 1px solid var(--gray-700);
  box-shadow: var(--shadow-lg);

  h3 {
    font-size: 2.8rem;
    color: var(--success);
    margin-bottom: var(--space-6);
    font-weight: 600;
  }

  button {
    padding: var(--space-4) var(--space-8);
    text-transform: uppercase;
    letter-spacing: 0.1em;
    font-weight: 600;
    width: 100%;
    max-width: 24rem;
  }
`
