import styled from "styled-components";


export const Container = styled.section`
  padding: var(--space-16) var(--space-8);
  background: linear-gradient(180deg, rgba(17,24,39,0.02) 0%, rgba(17,24,39,0.1) 100%);

  header {
    text-align: center;
    max-width: 76.8rem;
    margin: 0 auto var(--space-12);
    
    h2 {
      font-size: 4.8rem;
      font-weight: 700;
      background: linear-gradient(90deg, var(--primary-light), var(--success));
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      margin-bottom: var(--space-4);
    }
    
    p {
      font-size: 2rem;
      color: var(--gray-200);
      font-weight: 500;
    }
  }

  .contacts {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: var(--space-6);
    margin-bottom: var(--space-12);
    
    div {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: var(--space-4);
      background: linear-gradient(145deg, var(--gray-900), var(--gray-700));
      border: 1px solid var(--gray-700);
      border-radius: 1.6rem;
      padding: var(--space-6) var(--space-8);
      width: 100%;
      max-width: 36rem;
      transition: all var(--transition-normal);
      box-shadow: var(--shadow-md);
      
      &:hover {
        transform: translateY(-4px);
        border-color: var(--primary);
        box-shadow: var(--shadow-lg);
        background: linear-gradient(145deg, var(--primary), var(--success));
        
        img {
          transform: scale(1.1) rotate(-5deg);
        }
        
        a {
          color: var(--white);
        }
      }
      
      img {
        width: 2.8rem;
        height: 2.8rem;
        transition: transform var(--transition-normal);
        filter: brightness(0) invert(1);
      }
      
      a {
        color: var(--gray-200);
        font-size: 1.8rem;
        font-weight: 500;
        text-decoration: none;
        transition: color var(--transition-normal);
      }
    }
  }

  @media (max-width: 768px) {
    padding: var(--space-8) var(--space-4);
    
    header {
      h2 {
        font-size: 3.6rem;
      }
      
      p {
        font-size: 1.8rem;
      }
    }
    
    .contacts {
      flex-direction: column;
      gap: var(--space-4);
      
      div {
        padding: var(--space-4) var(--space-6);
        
        a {
          font-size: 1.6rem;
        }
      }
    }
  }
      
      img {
        width: 2.8rem;
        height: 2.8rem;
        transition: transform var(--transition-normal);
        filter: brightness(0) invert(1);
      }
      
      a {
        color: var(--gray-200);
        font-size: 1.8rem;
        font-weight: 500;
        text-decoration: none;
        transition: color var(--transition-normal);
      }
    }
  }

  @media (max-width: 768px) {
    padding: var(--space-8) var(--space-4);
    
    header {
      h2 {
        font-size: 3.6rem;
      }
      
      p {
        font-size: 1.8rem;
      }
    }
    
    .contacts {
      flex-direction: column;
      gap: var(--space-4);
      
      div {
        padding: var(--space-4) var(--space-6);
        
        a {
          font-size: 1.6rem;
        }
      }
    }
  }
  
`
/* old one - 2/1/2023 - 
@media(max-width: 960px){
    .contacts{
      flex-direction: column;
      div{
        width: 100%;
        flex-direction: column;
      }
    }

*/
