import { Container } from "./styles";
import TobyMessier from "../../assets/toby-better.jpg";
import ScrollAnimation from "react-animate-on-scroll";

export function About() {
  const metrics = [
    { label: "Total Exit Value", value: "$20M+" },
    { label: "Financial Risk Analysis", value: "$500B+" },
    { label: "Global Partnerships", value: "25+" },
    { label: "Healthcare Professionals Trained", value: "600+" },
    { label: "AI Platforms Built", value: "12" },
    { label: "Industry Conferences Led", value: "7" },
    { label: "Active Portfolio Companies", value: "4" },
    { label: "Venture-Backed Companies", value: "2" },
    { label: "Medical Doctorate", value: "✓" }
  ];

  return (
    <Container id="about">
      <div className="about-text">

        <ScrollAnimation animateIn="fadeInLeft">
          <h2>Metrics</h2>
        </ScrollAnimation>


        <div className="metrics-grid">
          {metrics.map((metric, index) => (
            <ScrollAnimation key={index} animateIn="fadeInUp" delay={(index + 1) * 0.1 * 1000}>
              <div className="metric-card">
                <h4>{metric.value}</h4>
                <p>{metric.label}</p>
              </div>
            </ScrollAnimation>
          ))}
        </div>


        <ScrollAnimation animateIn="fadeInLeft" delay={0.00 * 1000}>
          <h3>Story</h3>
        </ScrollAnimation>


        <ScrollAnimation animateIn="fadeInLeft" delay={0.1 * 1000}>
          <p>
            Driven by a hacker's mindset since high school, I've always been passionate about building transformative systems through technology. This drive led me to start a medical doctorate at 18, but I chose to pivot towards entrepreneurship to create broader impact.
          </p>
        </ScrollAnimation>
        <ScrollAnimation animateIn="fadeInLeft" delay={0.2 * 1000} style={{ marginTop: "2rem", marginBottom: "2rem" }}>
          <p>
            My journey spans multiple industries - from healthcare and finance to AI and climate tech. As both a founder and executive, I've developed a unique perspective on building and scaling innovative companies. Each venture has strengthened my ability to identify opportunities and execute across disciplines.
          </p>
        </ScrollAnimation>
        <ScrollAnimation animateIn="fadeInLeft" delay={0.3 * 1000} style={{ marginTop: "2rem", marginBottom: "2rem" }}>
          <p>
            Today, I combine technical expertise with strategic vision to build market-leading companies. Whether developing AI platforms or structuring complex partnerships, I focus on creating sustainable value through innovation and disciplined execution.
          </p>
        </ScrollAnimation>
      </div>
      <div className="about-image">
        <ScrollAnimation animateIn="fadeInRight" delay={0.20 * 1000}>
          <img src={TobyMessier} alt="Toby Messier" />
        </ScrollAnimation>
      </div>
    </Container>
  );
}
